<template>
  <div class="wrap">
    <p class="title">视频列表</p>
    <!-- 搜索 -->
    <a-form layout="inline">
      <a-form-item label="视频名称">
        <a-input v-model:value.trim="videoTitle" placeholder="请输入用户名称" size="small" allowClear></a-input>
      </a-form-item>
      <a-form-item label="发布时间">
        <a-range-picker size="small" v-model:value="rangeDate" />
      </a-form-item>
      <a-form-item class="btn-group">
        <a-button type="primary" size="small" @click="search">查询</a-button>
        <a-button size="small" @click="reset">重置</a-button>
        <a-button size="small" @click="allDelete">批量删除</a-button>
      </a-form-item>
    </a-form>
    <!-- 表格 -->
    <a-table
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      :dataSource="dataSource"
      :columns="columns"
      :pagination="pagination"
      :row-key="record => record.id"
      @change="handlePageChange"
    >
      <template #tag="row">
        <span>{{ getTagList(row) }}</span>
      </template>
      <template #cover="row">
        <img class="avatar" :src="row.record.cover" alt="封面" />
      </template>
      <template #action="row">
        <div class="action">
          <span :class="['edit', row.record.status === 0 ? 'offLine' : 'onLine']" @click="offLine(row)">{{
            row.record.status === 0 ? '下架' : '上架'
          }}</span>
          <span class="edit" @click="view(row)">查看</span>
          <span class="del" @click="del(row)">删除</span>
        </div>
      </template>
    </a-table>
  </div>
  <a-modal :visible="visible" title="查看视频" @cancel="handleCancel" :footer="null">
    <div id="mse-video" style="width: 100%;"></div>
  </a-modal>
</template>

<script>
import { defineComponent, createVNode, ref, toRefs, reactive, onMounted, computed, nextTick } from 'vue';
import api from '@/services';
import moment from 'moment';
import { Modal, message } from 'ant-design-vue';
import HlsPlayer from 'xgplayer-hls';
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    align: 'center',
    ellipsis: true
  },
  {
    title: '用户名称',
    dataIndex: 'nickName',
    align: 'center',
    ellipsis: true
  },
  {
    title: '用户ID',
    dataIndex: 'userId',
    align: 'center',
    ellipsis: true
  },
  {
    title: '视频标签',
    dataIndex: 'videoTag',
    align: 'center',
    slots: {
      customRender: 'tag'
    }
  },
  {
    title: '视频名称',
    dataIndex: 'videoTitle',
    align: 'center'
  },
  {
    title: '封面图片',
    dataIndex: 'cover',
    align: 'center',
    ellipsis: true,
    slots: {
      customRender: 'cover'
    }
  },
  {
    title: '发布时间',
    dataIndex: 'createTime',
    align: 'center'
  },
  {
    title: '操作',
    dataIndex: 'action',
    width: 200,
    align: 'center',
    slots: {
      customRender: 'action'
    }
  }
];
export default defineComponent({
  setup() {
    onMounted(() => {
      getVideoManageList();
    });
    const state = reactive({
      selectedRowKeys: [],
      // Check here to configure the default column
      loading: false,
    });

    let pagination = ref({
      pageSize: 5,
      current: 1,
      total: 0,
      showSizeChanger: true,
      pageSizeOptions: ["5", "20", "50", "100"],//每页中显示的数据
    })

    const visible = ref(false);
    const videoUrl = ref();

    const playerState = reactive({
      player: {}
    });

    const getTagList = computed(() => {
      return function(row) {
        let arr = [];
        if (row) {
          row.record.videoLabelList.map(item => {
            arr.push(item.labelName);
          });
        }

        return arr.join(' / ');
      };
    });

    // table 相关变量
    const tableState = reactive({
      // pageSize: 5,
      // curPage: 1,
      // total: 0,
      dataSource: [],
      columns
    });

    const createPlayer = () => {
      playerState.player = new HlsPlayer({
        id: 'mse-video',
        url: videoUrl.value,
        autoplay: true
      });
      document.getElementById('mse-video').style.width = '100%';
    };

    const destroyPlayer = () => {
      playerState.player.destroy();
    };

    // 条件筛选表单
    const formState = reactive({
      videoTitle: '',
      rangeDate: []
    });

    // 获取用户列表 table data
    const getVideoManageList = async () => {
      const params = {
        videoTitle: formState.videoTitle,
        startTime: formState.rangeDate.length ? moment(formState.rangeDate[0]).format('YYYY-MM-DD') + ' 00:00:00' : '',
        endTime: formState.rangeDate.length ? moment(formState.rangeDate[1]).format('YYYY-MM-DD') + ' 23:59:59' : '',
        curPage: pagination.value.current,
        pageSize: pagination.value.pageSize
      };

      const { data, success, msg } = await api.getVideoManageList(params);
      if (success) {
        tableState.dataSource = data.records;
        pagination.value.total = data.total;
      } else {
        message.error(msg);
      }
    };

    const handlePageChange = page => {
      pagination.value.current = page.current;
      if(page.pageSize != pagination.value.pageSize) {
        pagination.value.current = 1
      }
      pagination.value.pageSize = page.pageSize
      getVideoManageList();
    };

    // 下架
    const offLine = async row => {
      Modal.confirm({
        title: '',
        centered: true,
        content: createVNode(
          'div',
          {
            style: 'text-align: center;font-size: 18px;font-weight: bold;'
          },
          `确认要${row.record.status === 0 ? '下架' : '上架'}此视频吗？`
        ),

        async onOk() {
          console.log('row==', row);
          let params = {
            id: row.record.id,
            status: row.record.status ? 0 : 1
          };
          const { success } = await api.offLineVideo(params);
          if (success) {
            message.success(`${row.record.status === 0 ? '下架' : '上架'}成功！`);
            search();
          }
        }
      });
    };

    // 查看
    const view = async row => {
      visible.value = true;
      console.log('visible===', visible.value);
      videoUrl.value = row.record.playUrl;
      nextTick(() => {
        createPlayer();
      });
    };

    // 删除
    const del = row => {
      Modal.confirm({
        title: '',
        centered: true,
        content: createVNode(
          'div',
          {
            style: 'text-align: center;font-size: 18px;font-weight: bold;'
          },
          `确认要删除该视频吗？`
        ),

        async onOk() {
          let { success, msg } = await api.delVideo(row.record.id);
          if (success) {
            message.success('删除成功！');
            search();
          } else {
            message.error(msg);
          }
        }
      });
    };

    const search = () => {
      pagination.value.current = 1;
      getVideoManageList();
    };

    const reset = () => {
      pagination.value.current = 1;
      Object.keys(formState).map(key => (formState[key] = ''));
      getVideoManageList();
    };

    const handleCancel = () => {
      visible.value = false;
      destroyPlayer();
    };

    const onSelectChange = selectedRowKeys => {
      console.log('selectedRowKeys changed: ', selectedRowKeys);
      state.selectedRowKeys = selectedRowKeys;
    };

    const allDelete = async () => {
      let list = [];
      for(let i in state.selectedRowKeys){
        list.push(state.selectedRowKeys[i])
      }
      const params = {
        productionIdList: list
      };
      const { data }  = await api.allDeleteVideo(params)
      if(list.length > 0) {
        message.success('删除成功')
        search()
      } else {
        message.info('请选择需要删除的视频')
      }
    }

    return {
      ...toRefs(playerState),
      createPlayer,
      destroyPlayer,
      visible,
      videoUrl,
      getTagList,
      ...toRefs(formState),
      getVideoManageList,
      handlePageChange,
      search,
      reset,
      offLine,
      del,
      view,
      handleCancel,
      ...toRefs(tableState),
      pagination,
      ...toRefs(state),
      onSelectChange,
      allDelete
    };
  }
});
</script>

<style lang="less" scoped>
.wrap {
  padding: 15px 20px;
  flex-wrap: wrap;
  .title {
    font-weight: bold;
    font-size: 20px;
  }
  .action {
    display: flex;
    justify-content: flex-start;
    .ant-btn {
      margin-right: 20px;
    }
  }
  :deep(.ant-form) {
    display: flex;
    justify-content: center;
    margin: 15px 0;
    .btn-group {
      flex: 1;
      .ant-btn {
        margin-left: 20px;
      }
    }
  }
  :deep(.ant-table-wrapper) {
    margin-top: 15px;
    .ant-table {
      .avatar {
        width: 50px;
        height: 50px;
        object-fit: cover;
      }
      .status-tag,
      .action > span {
        display: inline-block;
        padding: 0px 10px;
        border-radius: 3px;
        font-size: 12px;
        height: 25px;
        line-height: 25px;
      }
      .status-tag {
        background-color: #4acc90;
        color: #fff;
      }

      .action {
        span {
          cursor: pointer;
          color: #fff;
        }
        .edit {
          background-color: #007fff;
          margin-right: 14px;
        }
        .offLine {
          background-color: #faa755;
        }
        .del {
          background-color: #d71345;
        }
      }
    }
  }
}
</style>
